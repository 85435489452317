<template>
  <div class="clue-detail">
    <Detail v-if="id && getUserInfo.isManager" :id="id">
      <template v-slot:btns="{data}">
        <div  class="btns">
          <van-button round class="btn" type="primary" @click="next(data)">{{ ['1', '2'].includes(activeTab) ? $t('分配') : $t('重新分配') }}</van-button>
        </div>
      </template>
    </Detail>
    <BusinessFilters
      ref="filter"
      v-model="showDistribution"
      :options="distributionOptions"
      :defaultActiveKey="['saIdList']"
      :buttonNameReset="$t('取消')"
      @reset="showDistribution=false"
      @submit="distributionFilter">
    </BusinessFilters>
  </div>
</template>
<script>
import Detail from '@/modules/clue/component/detail.vue'
import BusinessFilters from '@/components/v2/business/BusinessFilters/index.vue'
import commonMixin from './commonMixin'
import { ROLES } from '@/constants/constants'
export default {
  name: 'clueDetails',
  components: {
    Detail,BusinessFilters
  },
  mixins:[commonMixin],
  data() {
    const that = this
    return {
      id: '',
      clue: {},
      activeTab: this.$route.query.activeTab,
      distributionOptions:[{
        name: '',
        type: 'B_PEOPLE_SELECTION',
        field: 'saIdList',
        isSelection: true,
        valueType: 'object',
        shopIds:'',
        height:'80vh',        
        get roleCodes() {
          return [that.$route.query.activeTab==1? '1014001,1014002': (that.$route.query.activeTab == 2 ?'1014002' : that.getRoleCodes())] // 角色对应的code
        },
        filterData(data) {
          return that.getFilterData(data)
        }
      }]
    }
  },
  computed: {
    getUserInfo() {
      return this.$store.getters.userInfo
    },
  },
  created() {
    this.id = this.$route.query.id
  },
  methods: {
    getRoleCodes() {
      return this.activeTab === '3' ? (this.clue.followUserRoleCode || this.$route.query.followUserRoleCode) : ROLES.DCC
    },
    getFilterData(data) {
      const id = this.$route.query.followUserId || ''
      for (const key in data) {
        const child = data[key]
        child.map((item,index)=>{
          // if (item.id === id){
          //   child.splice(index,1)
          // }
        })
        if (child.length===0){
          delete data[key]
        }
      }
      return data
    },
    next(item) {
      this.clue = item
      this.showDistribution = true
    },
    distributionFilter(params) {
      const { saIdList } = params
      if (saIdList && saIdList.length){
        const { name , value } = saIdList[0]
        const requestUrl = {
          '1': 'handleDistribution',
          '2': 'handleDistribution',
          '3': 'setAssignRecycle',
          '4': 'setAssignCityShare'
        }[this.activeTab]
        let params = {
          empId:value,
          empName:name,
          ids:[this.clue.id],
        }
        if (['3','4'].includes(this.activeTab)) {
          params.roleLogo = this.activeTab === '3' ? this.clue.followUserRoleCode : ROLES.DCC
        }
        this[requestUrl](params).then(res=>{
          this.$toast(this.$t('分配成功'))
          // this.$store.commit('app/deleteKeepAlive', 'Distribution')
          if (window.history.length > 1) {
            setTimeout(()=>{
              this.$router.go(-1)
            },1000)
          } else {
            window.location.reload()
          }
        })
      } else {
        this.$toast(this.$t('请选择分配人员'))
      }
    }
  }
}
</script>
<style lang="less" scoped>
.clue-detail{
  padding: 16px;
  padding-bottom: 96px;
  /deep/ .btns{
    max-width: 500px;
    margin-left: -16px;
    width: 100%;
    height: 96px;
    display: flex;
    position: fixed;
    z-index: 999;
    bottom: 0px;
    align-items: center;
    background: #ffffff;
    box-sizing: border-box;
    padding: 0 16px;
    // justify-content: space-between;
    justify-content: flex-end;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
    font-size: 16px;
    color: @black;
    box-shadow: 0px 2px 8px 2px #ddd;
    .btn{
      // flex: 1;
      width: 190px;
      border-radius: 100px;
      cursor: pointer;
    }
  }
}
</style>
